import * as React from "react";

import { PageMeta, SecondaryPageTitle, SeriousTalk } from "../components/shared/secondary-page-elements";

import DownloadModal from "../components/shared/download-modal";
import Footer from "../components/sections/footer";
import Grid from "../components/shared/grid";
import { H5 } from "../components/shared/headings";
import Layout from "../components/shared/layout";
import { Link } from "gatsby";
import Navbar from "../components/navbar";
import Section from "../components/shared/section";
import Seo from "../components/shared/seo";

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <Seo 
        title={"Privacy Policy | Aug 6, 2019 | Copilot"}
      />
      <DownloadModal/>
      <Navbar/>
      <Section>
        <Grid>
          <SecondaryPageTitle>Privacy policy</SecondaryPageTitle>
          <PageMeta>
            <H5>August 6, 2019</H5>
            <p><Link to="/privacy-policy">See current version</Link></p>
          </PageMeta>
          <SeriousTalk>
              <p>Copilot Money, Inc. (“<b>Copilot</b>,” “<b>we</b>,” “<b>our</b>,”
              or “<b>us</b>”) respects your privacy. This Privacy Policy explains how Copilot
              collects, uses, discloses, and secures information about you through https://www.copilot.money
              (as may be updated, relocated, or otherwise modified from time to time) and
              Copilot’s related mobile applications, along with our related websites,
              networks, applications, our electronic communications (including email and text
              messages), and other services provided by us (collectively, the “<b>Service</b>”).
              By using the Service, you consent to the processing of your information as set
              forth in this Privacy Policy.</p>

              <p>Your use of the Service is also governed by our <Link to="/terms-of-service">Terms of Service</Link>. Capitalized terms used but not defined in this Privacy Policy are
              defined in the Terms of Service.</p>

              <p>In providing the Service, we collect and obtain
              information about individual consumers (“<b>End<u> </u>Users</b>”) to, among
              other things, provide a personal finance information management tool that
              allows you to track and organize your finances, including through the
              aggregation of your financial account information (“<b>Account Data</b>”) from
              financial institutions and other third-party data sources you select and with
              whom you have a contractual relationship (“<b>Third-Party Data Sources</b>”).</p>

              <p><span style={{fontVariant: "small-caps"}}>1.         </span><b>Information
              We Collect.<span style={{fontVariant: "small-caps"}}> </span></b>Copilot collects
              information from and about End Users in a variety of ways. This information
              includes:</p>

              <p>A.         <b><i>Information You Provide.</i></b> We
              collect information you provide when you use the Service or otherwise
              communicate with us, including your banking and financial account information for
              Third-Party Data Sources. If you register an account with us, we will collect
              the information you provide, such as your name, email address, login
              credentials for Third-Party Data Sources, budget names and amounts, bills, and
              monthly subscriptions. We will also collect your Account Data from Third-Party
              Data Sources on your behalf and any other information you submit through your use
              of the Service. We may also collect information through your communications
              with our customer-support team.</p>

              <p><span >B.         </span><b><i><span
              >Provider-Submitted Information.</span></i></b><span
              > Our third-party service providers may provide us
              information about End Users or assist us in obtaining the Account Data (including
              a list of the End User’s financial transactions) from Third-Party Data Sources.
              We use and disclose this information only in accordance with our provision of
              the Service to the End User.</span></p>

              <p>C.         <b><i>Device and Usage Information.</i></b>
              Like most online services, we automatically receive standard technical
              information when you interact with the Service, including browser and device
              information such as unique device identifiers, mobile phone carrier, internet
              protocol (IP) addresses (which may identify your general geographic location),
              browser types, and the date and time of your interactions. We also receive
              information about your interactions with our Service, such as which website
              pages you visited and how much time was spent on the page, and which user
              invited you via an identification code.</p>

              <p>We may collect this information using cookies or similar
              technologies. Cookies are pieces of information that are stored by your browser
              on the hard drive or memory of your device. Cookies enable us to personalize
              your experience on the Service, maintain a persistent session, passively
              collect demographic information, and monitor advertisements and other
              activities. The Service may use different kinds of cookies and other types of
              local storage (such as browser-based or plugin-based local storage). </p>

              <p>2.         <b>How We Use Your Information. </b>We use
              information in a variety of ways to provide the Service and to operate our
              business, including the following: </p>

              <p><span >A.         </span><b><i>Service-Related
              Usage.</i></b> We use the information we collect about and from you for a
              number of purposes, including providing and supporting the Service, analyzing
              how you use the Service, and better tailoring features. We also use the login
              credentials you provide for the Third-Party Data Sources to retrieve, analyze,
              and present to you your Account Data.</p>

              <p><span >B.         </span><b><i><span>Communications.</span></i></b><span> We use your information to communicate with you for Service-related
              purposes. For example, we may send email to the email address you provide to us</span><span
              > for informational and operational purposes, such as
              account management, customer service, system maintenance, and other
              Service-related purposes.</span></p>

              <p><span >C.         </span><b><i>Improve
              the Service.</i></b> We use the information that we collect: (1) to understand
              and analyze usage trends and preferences; (2) to monitor and analyze the
              effectiveness of the Service; and (3) to improve the Service and develop new
              products, services, features, and functionality. </p>

              <p><span >D.         </span><b><i>Marketing.</i></b>
              As permitted by applicable law, we may use information we collect about and
              from you for marketing purposes, such as providing you with promotional
              materials that may be useful, relevant, valuable, or otherwise of interest to
              you. Where required under applicable law, we’ll obtain your prior opt-in
              consent to send you electronic marketing communications. </p>

              <p><span >3.         <b>How We Share Your
              Information.</b> We do not share your information with (1)&nbsp;other companies
              for joint marketing purposes; or (2)&nbsp;any third parties so they can market
              to you. </span>We may share, transfer, or disclose your information if you
              consent to us doing so, as well as in the following circumstances:</p>

              <p>A.         <b><i>Service Providers</i></b>. We share
              information with third parties who provide services to us, such as customer
              service, interfacing with Third-Party Data Sources, analytics, website
              management, information technology, and other similar service providers.</p>

              <p>B.         <b><i>Comply with Legal Requirements</i></b>. We
              may disclose your information as we believe to be necessary or appropriate to:
              (1) comply with applicable law and legal processes; (2) respond to requests
              from public and government authorities, including public and government
              authorities outside your country of residence; (3) enforce our Terms of Service;
              (4) protect our rights, privacy, safety, or property, and/or that of our
              affiliates, you, or others; and (5) allow us to pursue available remedies or
              limit the damages that we may sustain. </p>

              <p>C.         <b><i>Corporate Transactions</i></b>. <span>We reserve the right to transfer your information to
              service providers, advisors, potential transactional partners, or other third
              parties in connection with the consideration, negotiation, or completion of a
              corporate transaction in which we are acquired by or merged with another
              company or we sell, liquidate, or transfer all or a portion of our assets.</span></p>

              <p>D.         <b><i>Aggregate, De-Identified Information</i></b>.
              We may use your data to create aggregate or statistical information that does
              not directly identify a specific person, and we may share that information. For
              example, we may share anonymous and aggregated reports and information on user
              demographics and traffic patterns with third parties.</p>

              <p>4.         <b>Information Security. </b>We take steps in
              an effort to treat your information securely and in accordance with this
              Privacy Policy. Unfortunately, no data transmission or storage system can be
              guaranteed to be 100% secure, and we cannot ensure or warrant the security of
              any information you provide to us. We do not accept liability for unintentional
              disclosure.</p>

              <p><span style={{ fontVariant: "small-caps", textTransform: "uppercase" }}>5.         </span><b>Children’s
              Privacy.<span style={{ fontVariant: "small-caps", textTransform: "uppercase" }}> </span></b>We
              do not knowingly collect, maintain, or use personal information from children
              under 18 years of age, and no part of the Service is directed to children under
              the age of 18. If you learn that your child has provided us with personal information
              without your consent, you may alert us at the contact information listed below.
              If we learn that we have collected any personal information from children under
              18, we will promptly take steps to delete such information and terminate the
              child’s account.</p>

              <p><span style={{fontVariant: "small-caps"}}>6.         </span><b>International<span
              style={{fontVariant: "small-caps"}}> </span>Users<span style={{fontVariant: "small-caps"}}>.
              </span></b>The Service is intended for users located within the United States.
              We make no representation that the Service is appropriate or available for use
              outside of the United States. Access to the Service from countries or territories
              or by individuals where such access is illegal is prohibited. If you are visiting
              our website from the European Union or other regions with laws governing data
              collection and use, then please note that you are agreeing to the transfer of
              your information to the United States and processing globally. By providing
              your information to the website, you consent to any transfer and processing in
              accordance with this Privacy Policy.</p>

              <p><span style={{fontVariant: "small-caps"}}>7.         </span><b>Your<span
              style={{fontVariant: "small-caps"}}> </span>Choices<span style={{fontVariant: "small-caps"}}>.
              </span></b><span style={{fontVariant: "small-caps"}}>I</span><span>f you do not want the Service to collect information
              through the use of cookies, your browser may allow you to be notified when you
              receive certain types of cookies and restrict or disable certain cookies. Each
              browser is different, so you should check your browser’s “Help” menu to learn
              how to change your cookie preferences. Please be aware that if you disable or
              reject cookies, some features may not work properly.</span></p>

              <p><span>You can opt-out of
              receiving further promotional emails from us by following the unsubscribe instructions
              provided in the promotional email you receive or by emailing us directly </span>at
              unsubscribe@copilot.money with &quot;OPT-OUT,&quot; &quot;UNSUBSCRIBE,&quot;
              &quot;STOP,&quot; or &quot;REMOVE&quot; in the subject line<span
             >.</span></p>

              <p><span >8.         <b>California
              Residents.</b></span></p>

              <p>California law requires us to disclose how we respond to
              web browser Do Not Track signals. Because there currently isn’t an industry or
              legal standard for responding to Do Not Track signals, we do not respond to
              them at this time.</p>

              <p><span >California law provides
              California residents with specific rights regarding their personal information.
              If you are a California resident, this section describes your rights and
              explains how to exercise those rights.</span></p>

              <p style={{ marginLeft: "0.5in" }}>
              <b><i>A.         Access to Specific Information and Data Portability
              Rights.</i></b></p>

              <p><span>You have the right to request
              that we disclose certain information to you about our collection and use of
              your personal information over the past 12 months. Once we receive and confirm
              your verifiable consumer request, we will disclose to you:</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}>
              <span style={{ fontFamily: "Symbol", color: "white" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >The categories of personal information
              we collected about you.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "white" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >The categories of sources for the
              personal information we collected about you.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "white" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Our business or commercial purpose for
              collecting or selling that personal information.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "white" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >The categories of third parties with
              whom we share that personal information.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "white" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >The specific pieces of personal
              information we collected about you (also called a data portability request).</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "white" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >If we sold or disclosed your personal
              information for a business purpose, two separate lists disclosing:</span></p>

              <p style={{ marginLeft: "1.25in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "white" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >sales, identifying the personal
              information categories that each category of recipient purchased; and</span></p>

              <p style={{ marginLeft: "1.25in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "white" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >disclosures for a business purpose,
              identifying the personal information categories that each category of recipient
              obtained.</span></p>

              <p ><b><i><span
              >B.         Deletion Request Rights</span></i></b></p>

              <p><span >You have the right to request
              that we delete any of your personal information that we collected from you and
              retained, subject to certain exceptions. Once we receive and confirm your
              verifiable consumer request, we will delete (and direct our service providers
              to delete) your personal information from our records, unless an exception
              applies.</span></p>

              <p><span >We may deny your deletion
              request if retaining the information is necessary for us or our service
              providers to:</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Complete the transaction for which we
              collected the personal information, provide a good or service that you
              requested, take actions reasonably anticipated within the context of our
              ongoing business relationship with you, or otherwise perform our contract with
              you.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Detect security incidents, protect
              against malicious, deceptive, fraudulent, or illegal activity, or prosecute
              those responsible for such activities.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Debug products to identify and repair
              errors that impair existing intended functionality.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Exercise free speech, ensure the right
              of another consumer to exercise their free speech rights, or exercise another
              right provided for by law.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Comply with the California Electronic
              Communications Privacy Act (Cal. Penal Code § 1546 seq.).</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Engage in public or peer-reviewed
              scientific, historical, or statistical research in the public interest that
              adheres to all other applicable ethics and privacy laws, when the information's
              deletion may likely render impossible or seriously impair the research's
              achievement, if you previously provided informed consent.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Enable solely internal uses that are
              reasonably aligned with consumer expectations based on your relationship with
              us.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Comply with a legal obligation.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Make other internal and lawful uses of
              that information that are compatible with the context in which you provided it.</span></p>

              <p ><b><i><span
              >C.         Exercising Access, Data Portability, and
              Deletion Rights</span></i></b></p>

              <p><span >To exercise the access, data
              portability, and deletion rights described above, please submit a verifiable
              consumer request to us by either:</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Calling us at 504-475-1474.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Visiting <Link to="/ccpa">https://www.copilot.money/ccpa</Link></span></p>

              <p><span >Only you or a person registered
              with the California Secretary of State that you authorize to act on your
              behalf, may make a verifiable consumer request related to your personal
              information. You may also make a verifiable consumer request on behalf of your
              minor child.</span></p>

              <p><span >You may only make a verifiable
              consumer request for access or data portability twice within a 12-month period.
              The verifiable consumer request must:</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Provide sufficient information that
              allows us to reasonably verify you are the person about whom we collected
              personal information or an authorized representative.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Describe your request with sufficient
              detail that allows us to properly understand, evaluate, and respond to it.</span></p>

              <p><span >We cannot respond to your
              request or provide you with personal information if we cannot verify your
              identity or authority to make the request and confirm the personal information
              relates to you. Making a verifiable consumer request does not require you to
              create an account with us. We will only use personal information provided in a
              verifiable consumer request to verify the requestor's identity or authority to
              make the request.</span></p>

              <p ><b><i><span
              >D.         Response Timing and Format</span></i></b></p>

              <p><span >We endeavor to respond to a
              verifiable consumer request within 45 days of its receipt. If we require more
              time (up to 90 days), we will inform you of the reason and extension period in
              writing. If you have an account with us, we will deliver our written response
              to that account. If you do not have an account with us, we will deliver our
              written response by mail or electronically, at your option. Any disclosures we
              provide will only cover the 12-month period preceding the verifiable consumer
              request's receipt. The response we provide will also explain the reasons we
              cannot comply with a request, if applicable. For data portability requests, we
              will select a format to provide your personal information that is readily
              useable and should allow you to transmit the information from one entity to
              another entity without hindrance.</span></p>

              <p><span >We do not charge a fee to
              process or respond to your verifiable consumer request unless it is excessive,
              repetitive, or manifestly unfounded. If we determine that the request warrants
              a fee, we will tell you why we made that decision and provide you with a cost
              estimate before completing your request.</span></p>

              <p ><b><i><span
              >E.         Non-Discrimination</span></i></b></p>

              <p><span >We will not discriminate against
              you for exercising any of your California privacy rights. Unless permitted by California
              law, we will not:</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Deny you goods or services.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Charge you different prices or rates
              for goods or services, including through granting discounts or other benefits,
              or imposing penalties.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Provide you a different level or
              quality of goods or services.</span></p>

              <p style={{ marginLeft: "1.0in", textIndent: "-.25in" }}><span
              style={{ fontFamily: "Symbol", color: "black" }}>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span><span >Suggest that you may receive a
              different price or rate for goods or services or a different level or quality
              of goods or services.</span></p>

              <p><span >9.         <b>Contact Us. </b><span
              >If you have any questions or comments about this
              Privacy Policy, our privacy practices, or if you would like to exercise your
              rights and choices, please contact us at support@copilot.money or by writing to
              us at: </span></span></p>

              <p style={{ marginLeft: ".5in" }}>Copilot Money,
              Inc.</p>

              <p style={{ marginLeft: ".5in" }}>45 Tudor City Place, Apt 1118</p>

              <p style={{ marginLeft: ".5in" }}>New York, NY 10017</p>

              <p>10.       <b>Privacy Policy Updates. </b>We may update
              this Privacy Policy from time to time. If we make any changes, we will notify
              you by revising the &quot;Last Updated&quot; date at the top of this Privacy
              Policy. If we make any revisions that materially change the ways in which we
              use or share the information previously collected from you through the Service,
              we will give you the opportunity to consent to such changes before applying
              them to that information.</p>
              </SeriousTalk>
            </Grid>
        </Section>
        <Footer/>
    </Layout>
  )
}

export default PrivacyPolicyPage
